import Layout from "../components/layout/layout"
import React from "react"
import SEO from "../components/seo.js"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Funky Veggie : 404: Not found" />
    <section className={"page-404 container"}>
      <h1>NON TROUVÉE</h1>
      <p>Cette page n'existe pas.</p>
    </section>
  </Layout>
)

export default NotFoundPage
